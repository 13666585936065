.custom_modal-wrapper {
  background-color: rgba(162, 165, 175, 0.45);
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zIndexModal;
  display: flex;

  .custom_modal-block {
    background-color: white;
    width: rem-calc(360);
    height: auto;
    border-radius: $sizeTwentyFour;
    padding: $sizeTwentyFour $sizeSixteen;
    margin: auto;
    position: relative;

    &--middle {
      width: rem-calc(480);
    }

    &--big {
      width: rem-calc(720);
    }

    .close {
      position: absolute;
      top: -$sizeThirtySix;
      right: -$sizeEight;
      cursor: pointer;
    }
  }

  .custom_modal-content {
    max-height: 70vh;
    overflow-y: auto;
  }

  .block-title {
    margin-bottom: $sizeFourteen;
    font-family: $fontAvertaSemiBold;

    span {
      display: inline-block;
      width: $sizeFiftyTwo;
    }
  }

  .description {
    margin-bottom: $sizeSixteen;
    &:last-child {
      margin-bottom: 0;
    }

    .subtitle {
      font-family: $fontAvertaBold;
    }
  }
}

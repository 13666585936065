.c-table-dashboard {
  display: table;
  $parent: &;

  &__wrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    container-type: inline-size;

    &.is-cloned {
      opacity: 0;
      pointer-events: none;
      background-color: $white;
      position: fixed;
      top: 0;
      z-index: 999999999;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      .c-table-dashboard {
        border-bottom: 1px solid $greyMedium;
      }

      &.tootlip-open {
        height: rem-calc(180);
        background: transparent;
      }
    }
  }

  &__header,
  &__body {
    display: table;
    width: 100%;
  }

  &__header {
    #{$parent}__cell {
      background-color: $greyLight !important;
      padding-inline: rem-calc(24);

      span {
        font-family: $fontAvertaBold;
        text-transform: capitalize;
      }

      &.is-first {
        padding-right: 0;
        z-index: 999999999;
      }
    }
  }

  &__body {
    border-bottom: 1px solid $greyMedium;
    #{$parent}__cell {
      &:last-child {
        justify-content: flex-start;
      }
      #{$parent}.is-blur & {
        span {
          filter: blur(4px)
        }
      }
    }
  }

  &__row {
    display: grid;
    border-top: 1px solid $greyMedium;
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
    $row: &;

    &-content {
      width: 100%;
      overflow: hidden;
      transition: all 700ms ease-in-out;
      transform: translateY(-300px);

      &-wrapper {
        cursor: default;
        display: grid;
        border-top: 0;
        overflow: hidden;
        width: 100cqw;
        grid-template-rows: 0fr;
        position: sticky;
        left: 0;
        opacity: 0;

        &.is-active {
          transition: all 700ms 400ms ease-in-out;
          opacity: 1;
          border-top: 1px solid $greyMedium;
          grid-template-rows: 1fr;
          padding-block: rem-calc(24);
          #{$row}-content {
            transform: translateY(0);
          }
        }
      }

      .wrapper-barsGraphic {
        width: 100%;
      }

      &-charts {
        display: flex;
        justify-content: center;
        gap: rem-calc(24);
        .headingH2 {
          border-bottom: rem-calc(1) solid lightgrey;
          padding-bottom: rem-calc(8);
        }
      }
    }
  }

  &__cell {
    border-right: 1px solid $greyMedium;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: calc(100% / 9);
    min-width: rem-calc(120);
    flex: 1;
    min-height: rem-calc(44);
    padding: rem-calc(4) rem-calc(8);
    position: relative;
    $cell: &;

    &:first-child {
      border-left: 1px solid $greyMedium;
    }
    &:nth-last-child(3),
    &:nth-last-child(2),
    &:last-child {
      // width: rem-calc(390);
      #{$cell}-tooltip-body {
        left: initial;
        right: 0;
      }
    }


    &.is-first {
      width: rem-calc(200);
      padding-left: 0;
      position: sticky;
      left: 0;
      background: white;
      z-index: 9999999;
    }

    &:last-child,
    &:nth-child(2) {
      span {
        filter: blur(0) !important;
      }
    }

    &-sort {
      transition: all 0.3s ease-in-out;
      will-change: transform;
      position: absolute;
      right: 0;
      cursor: pointer;
      z-index: $zIndexTooltip;

      &.is-active {
        transform: rotate(180deg);
      }
    }

    &-star {
      position: absolute;
      right: -.8rem;
      cursor: pointer;
      &.is-loading {
        animation: 1.5s linear infinite rotate;
      }
    }

    &-tooltip {
      cursor: pointer;
      height: rem-calc(20);
      position: absolute;
      right: 4px;
      top: 4px;
      // transform: translateY(-50%);

      svg {
        width: 16px;
        height: 16px;
      }

      > span {
        pointer-events: none;
      }

      &.opened &-body {
        opacity: 1;
        pointer-events: all;
      }

      &-body {
        transition: all 300ms ease-in-out;
        opacity: 0;
        width: max-content;
        max-width: rem-calc(280);
        top: rem-calc(24);
        pointer-events: none;
      }
    }

    &-first-content {
      display: flex;
      align-items: center;
      justify-content: center;
      position:relative;
      flex: 1;
      padding-inline: rem-calc(30);
    }

    &-change-user {
      border-right: 1px solid $greyMedium;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-chevron {
      transition: transform 0.3s ease-in-out;
      transform: rotate(0deg);
      position: absolute;
      left: 0;
      cursor: pointer;

      &.is-active {
        transform: rotate(-180deg);
      }
    }
  }

  &__notes {
    &-wrapper {
      &.is-active {
        .c-table-dashboard__notes-container textarea {
          pointer-events: all;
        }
        .c-table-dashboard__notes-container .wrapper-tabs {
          max-height: rem-calc(140);
          padding-top: rem-calc(24) !important;
        }
      }
    }
    &-container {
      textarea {
        pointer-events: none;
      }
      .wrapper-tabs {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        max-height: 0;
        overflow: hidden;
        transition: all 350ms ease-in-out;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
